<template>
    <div>Bye</div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'

export default {
    name: 'logout',
    data() {
        return {}
    },
    async created() {
        await this.setAuthenticated(false).then(() => {
            this.$router.push('/login').catch((errr) => { })
        })
    },
    methods: {
        ...mapActions({
            setAuthenticated: 'user/setAuthenticated'
        })
    }
}
</script>
